import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { setTokenCookie } from './shared/helpers/set-token-cookie.helper';

const cookies = new Cookies();
const aToken = process.env.REACT_APP_ACCESS_TOKEN || 'sc-at';
const rToken = process.env.REACT_APP_REFRESH_TOKEN || 'sc-rt';

const authUrlLink = createHttpLink({
    uri: process.env.REACT_APP_AUTH_API,
});

const apiUrlLink = createUploadLink({
    uri: process.env.REACT_APP_API,
});

const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from cookies if it exists
    let token = cookies.get(aToken);
    const refreshToken = cookies.get(rToken);
    // Attempt to refresh token when remaining expiry time less than 1 minute.
    if (token && refreshToken) {
        const { exp }: any = jwtDecode(token);
        const expiryTime = moment.unix(exp);
        if (expiryTime.diff(moment(), "minutes") < 3) {
            //refresh token
            token = await generateNewToken(refreshToken);
        }
    } else if (refreshToken) {
        token = await generateNewToken(refreshToken);
    }

    // return the headers to the context so httpLink can read them
    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`
        }
    }

    return { headers }
});

const generateNewToken = async (refreshToken: string) => {
    if (process.env.REACT_APP_AUTH_API) {
        const res = await fetch(process.env.REACT_APP_AUTH_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`

            },
            body: JSON.stringify({
                query: `
                    mutation RefreshAccessToken{
                        refreshAccessToken{
                            a
                        }
                    }
                `})
        });

        const result = await res.json();
        const newAccessToken = (result.data && result.data.refreshAccessToken) ?
            result.data.refreshAccessToken.a : null;
        if (newAccessToken) {
            setTokenCookie(aToken, newAccessToken);
            return newAccessToken;
        } else {
            const isProd = process.env.NODE_ENV === "production";
            cookies.remove(rToken, { path: "/", domain: isProd ? process.env.REACT_APP_COOKIE_DOMAIN : "localhost" });
        }
    }
    return null;
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(apiUrlLink),
    cache: new InMemoryCache({})
});

export const authClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(authUrlLink),
    cache: new InMemoryCache(),
});