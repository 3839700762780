import { gql } from '@apollo/client';

export const GET_DASHBOARD_DEPOSIT_STAT = gql`
query DashboardDepositStatistic($dashboardInput: DashboardInput) {
    dashboardDepositStatistic(dashboardInput: $dashboardInput) {
        monthlyDeposits{
            depositMonth,
            sourceAndCurrency,
            total
        },
        todayDeposit{
            sourceAndCurrency,
            total
        }
    }
}
`;

export const GET_DASHBOARD_WITHDRAWAL_STAT = gql`
query DashboardWithdrawalStatistic($dashboardInput: DashboardInput){
    dashboardWithdrawalStatistic(dashboardInput: $dashboardInput) {
        monthlyWithdrawals{
            withdrawalMonth,
            trustAccount,
            total
        },
        todayWithdrawal{
            trustAccount,
            total
        }
    }
}
`;

export const GET_DASHBOARD_EATRADE_STAT = gql`
query DashboardEATradesStatistic{
    dashboardEATradesStatistic{
        monthlyEATrades{
            tradeMonth,
            eaName,
            profit
        },
        eATradeLotSize{
            tradeMonth,
            eaName,
            lot
        }
    }
}
`;

export const GET_DASHBOARD_USER_STAT = gql`
query DashboardUsersStatistic{
    dashboardUsersStatistic{
        monthlyUsers{
            month,
            country,
            total
        }
    }
}
`;

export const GET_DASHBOARD_HEDGEFUND_STAT = gql`
query DashboardHedgeFundStatistic{
    dashboardHedgeFundStatistic{
        count,
        amount,
        dynamicCapital,
        thisMonthVol
    }
}
`;