import { Link, useLocation } from 'react-router-dom';
import {
    DashboardOutlined,
    InteractionOutlined,
    UserOutlined,
    SettingOutlined,
    CopyOutlined,
    TrophyOutlined,
    BarChartOutlined,
    SoundOutlined,
    ControlOutlined,
    FundOutlined
} from '@ant-design/icons';
import { Layout, Menu, MenuProps, Badge } from 'antd';
import './SideMenu.less';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { useQuery } from "@apollo/client";
import { client } from "../../../apollo-clients";
import { GET_INDICATOR } from "./SideMenu.gql";
import packageJson from '../../../../package.json';
const { Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

interface Props {
    collapsed: boolean;
    setCollapse: () => void;
};

const SideMenu = ({ collapsed, setCollapse }: Props) => {
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState<string[]>();
    // const [subMenuKey, setSubMenuKey] = useState<string>();
    const { authState } = useAuth();
    const [sideNavs, setSideNavs] = useState<any[]>();
    const { data, } = useQuery(GET_INDICATOR, {
        client, fetchPolicy: 'no-cache', pollInterval: 60000
    });
    const [indicatorList, setIndicatorList] = useState<any[]>();
    const { pathname } = location || { pathname: '/' };
    const screenWidth = window.outerWidth;
    const [subMenuKey, setSubMenuKey] = useState<string[]>();

    useEffect(() => {
        let keys: string[] = location.pathname.split("/");
        keys = keys.length >= 3 ? [`/${keys[1]}`, `/${keys[1]}/${keys[2]}`] : [`/${keys[1]}`];
        if(keys[0] === '/')
            setSelectedKeys(['/ ']);
        else
            setSelectedKeys(keys);
        if (screenWidth > 768) { setSubMenuKey([keys[0]]); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (data && data.indicators.length > 0) {
            setIndicatorList(data.indicators)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (authState.sideNav && authState.sideNav.length > 0) {
            const sortedData = authState.sideNav.sort((a, b) => {
                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
            })
            const parentSideNavs: any[] = sortedData.filter(sn => !sn.parentId);

            const nestedSideNav: any[] = parentSideNavs.map(sn => {
                const parentSideNav = { ...sn };
                parentSideNav.child = sortedData.filter(c => c.parentId === sn.id);
                return parentSideNav;
            });
            setSideNavs(nestedSideNav);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.sideNav])

    const getIcon = (iconName: string) => {
        switch (iconName) {
            case 'DashboardOutlined':
                return <DashboardOutlined />;
            case 'InteractionOutlined':
                return <InteractionOutlined />;
            case 'UserOutlined':
                return <UserOutlined />;
            case 'SettingOutlined':
                return <SettingOutlined />;
            case 'CopyOutlined':
                return <CopyOutlined />
            case 'TrophyOutlined':
                return <TrophyOutlined />
            case 'BarChartOutlined':
                return <BarChartOutlined />
            case 'SoundOutlined':
                return <SoundOutlined />
            case 'ControlOutlined':
                return <ControlOutlined />
            case 'FundOutlined':
                return <FundOutlined />
            default:
                break;
        }
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type
        } as MenuItem;
    }

    const items = (): any[] => {
        return sideNavs?.map(s => {
            var indicator = indicatorList?.filter(u => u.parentId === s.id && u.isShowIndicator === true);
            var isShowIndicator = indicator && indicator.length > 0 ? true : false;
            if (s.child && s.child.length > 0) {
                return getItem(<span>{s.name}<Badge dot={isShowIndicator} style={{ marginLeft: 5 }} /></span>, s.route, getIcon(s.icon),
                    s.child.map((child: any) => {
                        var childIndicator = indicatorList?.find(u => u.id === child.id) == null ? false
                            : indicatorList?.find(u => u.id === child.id).isShowIndicator;
                        return getItem(
                            <Link to={child.route}> {child.name}
                                <Badge dot={childIndicator} />
                            </Link>, child.route);
                    }));
            }
            return getItem(<Link to={s.route}>{s.name}</Link>, s.route, getIcon(s.icon));
        }) || []
    };

    const handleMenuItemClick = () => {
        if (screenWidth <= 768) {
            setCollapse();
        }
    }

    const handleSubMenuClick = (e: any) => {
        if (e && e.length > 1) {
            setSubMenuKey([e[1]]);
        } else if (e && e.length > 0) {
            setSubMenuKey([e[0]]);
        } else if (screenWidth <= 768 && e && e.length === 0) {
            setSubMenuKey(['/']);
        } else if (e.length === 0) {
            setSubMenuKey(['/']);
        }
    };

    return (
        <Sider className="slider-container" width={256} trigger={null} collapsible collapsed={collapsed} collapsedWidth={window.innerWidth < 768 ? 0 : 80}>
            <Menu theme="dark" mode="inline"
                triggerSubMenuAction={collapsed ? "hover" : "click"}
                defaultSelectedKeys={['/']}
                selectedKeys={selectedKeys}
                openKeys={collapsed ? undefined : subMenuKey}
                onOpenChange={handleSubMenuClick}
                onClick={handleMenuItemClick}
                items={items()} />
            <div className="version-text">
                v {packageJson.version}
            </div>
        </Sider>
    )
}

export default SideMenu;