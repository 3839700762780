import { Button, Form, DatePicker } from 'antd';
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
const { RangePicker } = DatePicker;

interface Props {
    setFilters: Dispatch<SetStateAction<{ startDate: Date, endDate: Date }>>;
}

const DashboardFilter = ({ setFilters }: Props) => {
    const [queryForm] = Form.useForm();

    const onFinish = (fieldsValue: any) => {
        emitFilter(fieldsValue.datePicker);
    }

    const emitFilter = (datePicker: any[]) => {
        const startDate = datePicker && datePicker.length > 0 ?
        startOfDay(new Date(datePicker[0])) :
        new Date(moment().startOf('month').toString());
        const endDate = datePicker && datePicker.length > 0 ?
        endOfDay(new Date(datePicker[1])) :
        endOfDay(new Date());
        setFilters({startDate, endDate});
    }

    return (
        <>
            <Form layout="inline"
                form={queryForm}
                onFinish={onFinish}
                initialValues={{ datePicker: [moment(), moment()] }}
                className="filter-panel" >
                <Form.Item name="datePicker" label="Date">
                    <RangePicker />
                </Form.Item>
                <Form.Item className="filter-button-group">
                    <Button type="primary" htmlType="submit">Search</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default DashboardFilter;
