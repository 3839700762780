import { useAuth } from "../../hooks/auth/AuthContext";

export const GetRoleType = (pathName: string) => {
    const { authState } = useAuth();
    const roles = authState.sideNav.filter(sn => pathName.includes(sn.route));

    const role: any = authState && authState.sideNav ? authState.sideNav.find((sn) => {
        return sn.route.trim() === pathName;
    }) : {};

    return role && role?.roleType ? role?.roleType :
        (roles && roles.length > 0 ? roles[roles.length - 1]?.roleType : '');
}

export const sortListByLabel = (list: {
    value: string;
    label: string;
}[]) => {
    return list.sort(function compare(a, b) {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    })
}

export const formatRoles = (role: string) => {
    if (role.length === 4) {
        return 'RAWD'
    } else if (role.length === 3) {
        if (!role.includes('R')) {
            return 'RAWD'
        } else if (role.includes('A') && role.includes('W') && role.includes('R')) {
            return 'RWA'
        } else if (role.includes('A') && role.includes('D') && role.includes('R')) {
            return 'RAD'
        } else {
            return 'RWD'
        }
    } else if (role.length === 2) {
        if (role.includes('R') && role.includes('A')) {
            return 'RA'
        } else if (role.includes('R') && role.includes('W')) {
            return 'RW'
        } else if (role.includes('R') && role.includes('D')) {
            return 'RD'
        } else {
            if (role.includes('A') && role.includes('W')) {
                return 'RWA'
            } else if (role.includes('A') && role.includes('D')) {
                return 'RAD'
            } else {
                return 'RWD'
            }
        }
    } else {
        if (!role.includes('R'))
            return 'R' + role
        else
            return role
    }
}