import { useQuery } from "@apollo/client";
import { noneCurrencyThousandSeparator, thousandSeparator } from "../../shared/helpers/number-formatter";
import { GET_DASHBOARD_HEDGEFUND_STAT } from "./Dashboard.gql";

const DashboardHedgeFundChart = () => {
    const { data } = useQuery(GET_DASHBOARD_HEDGEFUND_STAT, { fetchPolicy: 'cache-and-network' });

    return (
        <>
            <div className="half-chart">
                <div>
                    <div>
                        <div className="chart-title">Total Hedgefund Investors</div>
                        <div className="chart-value">{noneCurrencyThousandSeparator(data?.dashboardHedgeFundStatistic?.count || 0)}</div>
                    </div>
                    <div>
                        <div className="chart-title">Total Fund</div>
                        <div className="chart-value">{thousandSeparator(data?.dashboardHedgeFundStatistic?.amount || 0)}</div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="chart-title">Capital at MT5</div>
                        <div className="chart-value">{thousandSeparator(data?.dashboardHedgeFundStatistic?.dynamicCapital || 0)}</div>
                    </div>
                    <div>
                        <div className="chart-title">Current Month Traded Lot</div>
                        <div className="chart-value">{noneCurrencyThousandSeparator(data?.dashboardHedgeFundStatistic?.thisMonthVol || 0)}</div>
                    </div>
                </div>
                <div>Hedgefund Statistic</div>
            </div>
        </>
    );
};
export default DashboardHedgeFundChart;