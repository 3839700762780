import { gql } from '@apollo/client';

export const GET_CRYPTO_WALLET = gql`
query GetCryptoWallets($pageNo:Int,$pageSize:Int,$cryptoWalletRequestInput:CryptoWalletRequestInput){
    getCryptoWallets(pageNo:$pageNo,pageSize:$pageSize,cryptoWalletRequestInput:$cryptoWalletRequestInput){
        count(cryptoWalletRequestInput: $cryptoWalletRequestInput),
        cryptoWallets {
            id,
            userId,
            memberId,
            userName,
            rPoint,
            wPoint,
            remainingRPoint,
            remainingWPoint,
            status,
            walletAddress,
            createdAt,
            updatedAt
        }
    }
}
`;

export const GET_CRYPTO_WALLET_ID = gql`
query CryptoWalletById($id:String!){
    cryptoWalletById(id:$id){
        id,
        userId,
        memberId,
        userName,
        rPoint,
        wPoint,
        remainingRPoint,
        remainingWPoint,
        status,
        walletAddress,
        createdAt,
        updatedAt,
    }
}
`;

export const UPDATE_CRYPTO_WALLET_ID = gql`
mutation UpdateCryptoWallet($cryptoWalletUpdateInput:CryptoWalletUpdateInput!){
    updateCryptoWallet(cryptoWalletUpdateInput:$cryptoWalletUpdateInput){
        status,
        id
    }
}
`;

export const DELETE_CRYPTO_WALLET = gql`
mutation DeleteCryptoWallet($id:String!){
    deleteCryptoWallet(id:$id){
        status,
        id
    }
}
`;
