import { DualAxes, DualAxesConfig } from '@ant-design/charts';
import { useQuery } from '@apollo/client';
import { thousandSeparator } from '../../shared/helpers/number-formatter';
import { GET_DASHBOARD_EATRADE_STAT } from './Dashboard.gql';

const DashboardEATradeChart = () => {
    const { data, loading } = useQuery(GET_DASHBOARD_EATRADE_STAT, { fetchPolicy: 'cache-and-network' });

    const config: DualAxesConfig = {
        data: [data?.dashboardEATradesStatistic?.monthlyEATrades || [], data?.dashboardEATradesStatistic?.eATradeLotSize || []],
        xField: 'tradeMonth',
        yField: ['profit', 'lot'],
        geometryOptions: [
            {
                geometry: 'column',
                isGroup: true,
                seriesField: 'eaName',
                columnWidthRatio: 0.4,
            },
            {
                geometry: 'line',
                seriesField: 'eaName',
                lineStyle: ({ name }) => {
                    if (name === 'a') {
                        return {
                            lineDash: [1, 4],
                            opacity: 1,
                        };
                    }

                    return {
                        opacity: 0.5,
                    };
                },
            },
        ],
        meta: { profit: { formatter: thousandSeparator } }
    };


    return (
        <div className='one-chart'>
            <DualAxes {...config} loading={loading} />
            <div>Last 6 Months EA Trade Performance</div>
        </div>
    );
};
export default DashboardEATradeChart;