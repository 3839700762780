import { Column, ColumnConfig, measureTextWidth, Pie, PieConfig } from "@ant-design/charts";
import { useQuery } from "@apollo/client";
import { endOfDay, startOfDay } from "date-fns";
import { each, groupBy } from "lodash";
import { useEffect, useState } from "react";
import { thousandSeparator } from "../../shared/helpers/number-formatter";
import { GET_DASHBOARD_DEPOSIT_STAT } from "./Dashboard.gql";
import DashboardFilter from "./DashboardFilter";

const DashboardDepositCharts = () => {
    const [filters, setFilters] = useState({ startDate: startOfDay(new Date()), endDate: endOfDay(new Date()) });
    const { data, loading } = useQuery(GET_DASHBOARD_DEPOSIT_STAT, {
        variables: { dashboardInput: filters }, fetchPolicy: 'cache-and-network'
    });
    const [annotations, setAnnotations] = useState<any[]>([]);

    useEffect(() => {
        if (data && data.dashboardDepositStatistic) {
            const ann: any[] = [];
            each(groupBy(data?.dashboardDepositStatistic?.monthlyDeposits, 'depositMonth'), (values, k) => {
                const value = values.reduce((a, b) => a + b.total, 0);
                ann.push({
                    type: 'text',
                    position: [k, value],
                    content: `${(value / 1000000).toFixed(1) + 'M'}`,
                    style: {
                        textAlign: 'center',
                        fontSize: 12,
                        fontWeight: 'bold',
                        fill: 'rgba(0,0,0,0.85)',
                    },
                    offsetY: -1,
                });
            });
            setAnnotations(ann);
        }
    }, [data]);

    const monthConfig: ColumnConfig = {
        data: data?.dashboardDepositStatistic?.monthlyDeposits || [],
        isStack: true,
        xField: 'depositMonth',
        yField: 'total',
        seriesField: 'sourceAndCurrency',
        interactions: [
            {
                type: 'active-region',
                enable: false,
            },
        ],
        connectedArea: {
            style: (oldStyle: any, element: any) => {
                return {
                    fill: 'rgba(0,0,0,0.25)',
                    stroke: oldStyle.fill,
                    lineWidth: 0.5,
                };
            },
        },
        annotations,
        meta: { total: { formatter: thousandSeparator } }
    };

    const dayConfig: PieConfig = {
        appendPadding: 8,
        data: data?.dashboardDepositStatistic?.todayDeposit || [],
        angleField: 'total',
        colorField: 'sourceAndCurrency',
        radius: 1,
        innerRadius: 0.6,
        autoFit: false,
        height: 300,
        label: {
            type: 'inner',
            offset: '-50%',
            content: (data, mappingData) => {
                return (+data.percent * 100).toFixed(2) + '%'
            },
            style: {
                textAlign: 'center',
                fontSize: 12,
            },
        },
        legend: {
            layout: 'horizontal',
            position: 'top'
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
        statistic: {
            title: {
                offsetY: -3,
                style: {
                    fontSize: '16px',
                },
                customHtml: (container, view, datum) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                    const text = datum ? datum.sourceAndCurrency : 'Total';
                    return renderStatistic(d, text, {
                        fontSize: 12,
                    });
                },
            },
            content: {
                offsetY: 3,
                style: {
                    fontSize: '16px',
                },
                customHtml: (container, view, datum, data: any) => {
                    const { width } = container.getBoundingClientRect();
                    const text = datum ? `USD ${datum.total.toFixed(2)}` : `USD ${data.reduce((r: any, d: any) => r + d.total, 0).toFixed(2)}`;
                    return renderStatistic(width, thousandSeparator(text), {
                        fontSize: 12,
                    });
                },
            },
        }
    };

    const renderStatistic = (containerWidth: number, text: string, style: any) => {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2;
        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    return (
        <div className="two-one-chart">
            <div>
                <Column {...monthConfig} loading={loading} />
                <div>Last 6 Months Deposits</div>
            </div>
            <div>
                <DashboardFilter setFilters={setFilters}/>
                <Pie {...dayConfig} loading={loading} />
                <div>Today Deposit</div>
            </div>
        </div>
    );
}

export default DashboardDepositCharts;