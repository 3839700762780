import { Alert } from "antd";

const NoPermissionToViewAlert = () => {
    return (
        <Alert
            className="auth-error"
            message='You do not have the necessary permission to view this page!'
            type="error"
            showIcon />
    )
}

export default NoPermissionToViewAlert;