
export const thousandSeparator = (value:any) => {
	return value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const noneCurrencyThousandSeparator = (value:any) => {
	return value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const decimal = (input:number, precision:number) => {
	return input.toFixed(9).slice(0, -9 + precision)
}