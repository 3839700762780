import { Layout } from 'antd';
import './GeneralFooter.less';
const { Footer } = Layout;

const GeneralFooter = () => {
    return (
        <Footer className="site-layout-footer">Admin Portal ©{new Date().getFullYear().toString()}</Footer>
    )
}

export default GeneralFooter;