import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, Select, Upload } from 'antd';
import { RequiredMark } from "antd/lib/form/Form";
import { GET_CRYPTO_WALLET, GET_CRYPTO_WALLET_ID, UPDATE_CRYPTO_WALLET_ID} from './CryptoWallet.gql';
import { useLazyQuery, useMutation } from "@apollo/client";
import useSpinner from "../../hooks/layout/useSpinner";
import useNotification from "../../hooks/layout/useNotification";
import { UploadOutlined } from '@ant-design/icons';
import NoPermissionToViewAlert from "../../shared/components/no-permission-alert/NoPermissionToView";
import { GetRoleType } from "../../shared/helpers/general.helpers";

const UpdatePointCryptoWalletForm = () => {
    const location = useLocation();
    const roleType = GetRoleType(location.pathname);
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const { setLoading } = useSpinner();
    const history = useHistory();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');
    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const [getCryptoWalletById, { data: getData, loading: getLoading }] = useLazyQuery(GET_CRYPTO_WALLET_ID, { fetchPolicy: 'network-only' });
    const [updatedCryptoWallet, { data: updateData, error: updateError, loading: updateLoading }] = useMutation(UPDATE_CRYPTO_WALLET_ID,
        { refetchQueries: [{ query: GET_CRYPTO_WALLET_ID, variables: { id: id } }], errorPolicy: 'all' });

    useEffect(() => {
        if (id) {
            getCryptoWalletById({ variables: { id } });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getLoading || updateLoading)
            setLoading(true);
        else
            setLoading(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLoading, updateLoading]);

    const setDefaultPoint = (wallet: any, walletType: string): number => {
        var remainingPoint = 0
        if (walletType === 'RPOINT') {
            if (wallet.rPoint && wallet.rPoint !== null) {
                remainingPoint = wallet.rPoint / 10
            }

            if (wallet.remainingRPoint && wallet.remainingPoint !== null) {
                var newRemainingRPoint = wallet.remainingRPoint
                remainingPoint = Math.min(remainingPoint, newRemainingRPoint)
            }
        }
        else if (walletType === 'WPOINT') {
            if (wallet.wPoint && wallet.wPoint !== null) {
                remainingPoint = wallet.wPoint / 10
            }

            if (wallet.remainingRPoint && wallet.remainingWPoint !== null) {
                var newRemainingWPoint = wallet.remainingWPoint
                remainingPoint = Math.min(remainingPoint, newRemainingWPoint)
            }
        }
        
        return remainingPoint
    }

    useEffect(() => {
        if (getData) {
            let cryptoWallet = getData?.cryptoWalletById ? getData.cryptoWalletById : null;
            if (cryptoWallet) {
                cryptoWallet.rPoint = setDefaultPoint(cryptoWallet, 'RPOINT');
                cryptoWallet.wPoint = setDefaultPoint(cryptoWallet, 'WPOINT');
                form.setFieldsValue({
                    id: cryptoWallet.id,
                    userId: cryptoWallet.userId,
                    memberId: cryptoWallet.memberId,
                    userName: cryptoWallet.userName,
                    rPoint: cryptoWallet.rPoint.toFixed(3),
                    wPoint: cryptoWallet.wPoint.toFixed(3),
                    status: cryptoWallet.status,
                    strMemberId: 'P' + cryptoWallet.memberId
                });
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData]);

    useEffect(() => {
        if (updateError) {
            setErrorNotification('Invalid R-Point Or W-Point Amount!');
        } else if (updateData) {
            var isCompleted = true
            if (updateData.updateCryptoWallet && updateData.updateCryptoWallet !== null ) {
                if (updateData.updateCryptoWallet.status && updateData.updateCryptoWallet.status !== null) {
                    if (updateData.updateCryptoWallet.status === 'FAIL') {
                        isCompleted = false
                    }
                }
            }

            if (!isCompleted) {
                setErrorNotification('Invalid R-Point Or W-Point Amount!')
            }
            else {
                setSuccessNotification('Update Crypto Wallet Successful!');
                history.push(`/members/crypto-wallet-transaction`);
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, updateError]);

    const onFinish = (fieldsValue: any) => {
        if (id) {
            updatedCryptoWallet({
                variables: {
                    cryptoWalletUpdateInput: {
                        id,
                        rPoint: +fieldsValue.rPoint,
                        wPoint: +fieldsValue.wPoint,
                    }
                }
            });
        }
    }
    
    return (
        <>
            {roleType?.includes('W') ?
                <>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item>Members</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="members/crypto-wallet-transaction">Crypto Wallet Transaction</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{id ? 'Edit' : 'Add'}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="content-header">
                        <h1> {id ? 'Edit Crypto Wallet Transaction' : 'Add Crypto Wallet Transaction'}</h1>
                    </div>
                    <div className="content-form">
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onValuesChange={onRequiredTypeChange}
                            requiredMark={requiredMark}>
                            {id &&
                                <Form.Item
                                    name="userName" label="User ID"
                                    rules={[{ required: true, message: 'Please input Phone Number!' }]}>
                                    <Input disabled={!!id} style={{ width: '100%' }} />
                                </Form.Item>
                            }
                            {id &&
                                <Form.Item
                                    name="strMemberId" label="Member ID"
                                    rules={[{ required: true, message: '' }]}>
                                    <Input disabled={!!id} style={{ width: '100%' }} />
                                </Form.Item>
                            }
                            <Form.Item name="rPoint" label="R-Point" required tooltip="This is a required field"
                                rules={[
                                    { required: true, message: 'Please input W-Point' },
                                    {
                                        pattern: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/,
                                        message: 'r-Point must be in decimal format (max 3 decimal point)!'
                                    }
                                ]}>
                                <Input placeholder="R-Point" />
                            </Form.Item>
                            <Form.Item name="wPoint" label="W-Point" required tooltip="This is a required field"
                                rules={[
                                    { required: true, message: 'Please input W-Point' },
                                    {
                                        pattern: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/,
                                        message: 'W-Point must be in decimal format (max 3 decimal point)!'
                                    }
                                ]}>
                                <Input placeholder="W-Point" />
                            </Form.Item>
                            <Form.Item className="form-button">
                                <Button type="primary" htmlType="submit">
                                    {id ? 'Save' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </> :
                <NoPermissionToViewAlert />
            }
        </>
    );
};

export default UpdatePointCryptoWalletForm;
