import { Line, LineConfig } from "@ant-design/charts";
import { useQuery } from "@apollo/client";
import { GET_DASHBOARD_USER_STAT } from "./Dashboard.gql";


const DashboardUserChart = () => {
    const { data, loading } = useQuery(GET_DASHBOARD_USER_STAT, { fetchPolicy: 'cache-and-network' });

    const config: LineConfig = {
        data: data?.dashboardUsersStatistic?.monthlyUsers || [],
        xField: 'month',
        yField: 'total',
        seriesField: 'country',
        // yAxis: {
        //     label: {
        //         formatter: (v: any) => `${(v / 10e8).toFixed(1)} B`,
        //     },
        // },
        legend: {
            position: 'top',
        },
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };


    return (
        <div className='one-chart'>
            <Line {...config} loading={loading} />
            <div>User Growth</div>
        </div>
    );
};
export default DashboardUserChart;